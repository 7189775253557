import { useRef } from 'react';
import {
    throttle,
    isObject,
    isString,
    groupBy,
} from 'lodash-es';
import type {
    Control,
    EditorModel,
    Elements,
    Group,
    GroupedByPositionControl,
} from '@apostroph/types';

import {
    RootState,
    Dispatch,
    useEditorSelector,
    useEditorDispatch,
} from '../store';
import { useDependencyEditorContext } from '../context';

function isOmitedElementsGuards(
    element: unknown,
): element is Omit<Elements, 'id'> {
    const checker = element as Elements;
    return (
        isObject(checker) &&
        Boolean(checker?.type) &&
        Boolean(checker?.childrenElement) &&
        Boolean(checker?.meta) &&
        Boolean(!checker?.id)
    );
}

function isId(id: unknown): id is string {
    return typeof id === 'string';
}

function is<T>(id: unknown): id is T {
    return true;
}

const useEditor = () => {
    const editor = useEditorSelector(
        //@ts-ignore
        (state: RootState) => state.editor,
    );
    const dispatch = useEditorDispatch<Dispatch>();

    const {
        controls,
        groups,
        rootElements,
        pluginElements,
    } = useDependencyEditorContext();

    const handleCreateElement = (
        payload: Omit<Elements, 'id'> | unknown,
        where?: string | unknown,
    ) => {
        if (
            isOmitedElementsGuards(payload) &&
            isId(where)
        ) {
            return dispatch.editor.createElement({
                payload,
                where,
            });
        }
        if (isOmitedElementsGuards(payload)) {
            return dispatch.editor.createElement({
                payload,
            });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const handleInsertElement = (
        payload: Omit<Elements, 'id'>,
        anchor: string,
        where: string,
        // mod?: 'after' | 'before',
    ) => {
        if (
            isOmitedElementsGuards(payload) &&
            isId(where) &&
            isString(anchor)
        ) {
            return dispatch.editor.insertElement({
                payload,
                where,
                anchor,
            });
        }
        if (
            isOmitedElementsGuards(payload) &&
            isString(anchor)
        ) {
            return dispatch.editor.insertElement({
                payload,
                anchor,
            });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const handleUpdateElement = (payload: Elements) =>
        dispatch.editor.updateElement(payload);

    const handleRemoveElement = ({
        what,
        parent,
    }: {
        what: string | unknown;
        parent?: string | unknown;
    }) => {
        if (isId(what) && isId(parent)) {
            return dispatch.editor.removeElement({
                what,
                parent,
            });
        }
        if (isId(what)) {
            return dispatch.editor.removeElement({ what });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const handleMoveElementUp = ({
        what,
        parent,
    }: {
        what: string | unknown;
        parent?: string | unknown;
    }) => {
        if (parent) {
            if (isId(what) && isId(parent)) {
                return dispatch.editor.moveElementUp({
                    what,
                    parent,
                });
            }
        } else if (isId(what)) {
            return dispatch.editor.moveElementUp({ what });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const handleMoveElementDown = ({
        what,
        parent,
    }: {
        what: string | unknown;
        parent?: string | unknown;
    }) => {
        if (parent) {
            if (isId(what) && isId(parent)) {
                return dispatch.editor.moveElementDown({
                    what,
                    parent,
                });
            }
        } else if (isId(what)) {
            return dispatch.editor.moveElementDown({
                what,
            });
        }
        throw new Error('handleCreateElement - wtf?');
    };

    const throttledHandleUpdateBlock = useRef(
        throttle(handleUpdateElement, 1000),
    );

    const getControls = (
        typeBlock: string,
    ): {
        controls: GroupedByPositionControl;
        groups: Array<Group>;
    } => {
        const currentControls = controls?.[typeBlock] || [];
        const currentGroups = groups?.[typeBlock] || [];

        const splitControls = currentControls.reduce<
            Array<Control>
        >((acc, control) => {
            if (Array.isArray(control.position)) {
                const splittedControl =
                    control.position.map((item) => ({
                        ...control,
                        position: item,
                    }));
                return [...acc, ...splittedControl];
            }
            return [...acc, control];
        }, []);

        const groupedControlsbyPostion = groupBy(
            splitControls,
            'position',
        ) as unknown as GroupedByPositionControl;

        return {
            controls: groupedControlsbyPostion,
            groups: currentGroups,
        };
    };

    const getParent = (parentId: string | unknown) => {
        if (isId(parentId)) {
            return editor.data[parentId];
        }
        return null;
    };

    const getTemplate = (type: string | unknown) => {
        if (isString(type)) {
            const tempalteRoot =
                rootElements?.[type]?.emptyObject;
            const templatePlugin =
                pluginElements?.[type]?.emptyObject;

            if (templatePlugin) {
                return templatePlugin;
            }
            if (tempalteRoot) {
                return tempalteRoot;
            }
            return null;
        }
        return null;
    };

    const getCurrentDataElement = <T>(
        id: string,
    ): { meta: T; childrenElement: Array<string> } => {
        const { meta } = editor.data[id];
        if (is<T>(meta)) {
            return {
                meta,
                childrenElement:
                    editor.data[id].childrenElement,
            };
        }
        throw new Error(
            'get current data element get id but not has Node with that id',
        );
    };

    const getCurrentState = () => editor;

    return {
        editor,
        handleInit: (state: EditorModel) =>
            dispatch.editor.initEditor(state),
        handleInsertElement,
        handleCreateElement,
        handleUpdateElement:
            throttledHandleUpdateBlock.current,
        handleRemoveElement,
        handleMoveElementUp,
        handleMoveElementDown,
        getControls,
        getParent,
        getTemplate,
        getCurrentDataElement,
        getCurrentState,
    };
};

export { useEditor };
export default { useEditor };
