/* eslint-disable @typescript-eslint/no-misused-promises */
import React, {
    FC,
    ReactElement,
    useCallback,
    useEffect,
} from 'react';
import { renderToString } from 'react-dom/server';
import { v4 as uuidv4 } from 'uuid';
import { Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import type {
    ArticleForm,
    ArticleFormExtend,
} from '@mega/api';

import { Button, FormLayout } from '@mega/ui';
import { useSnackbar } from 'notistack';
import {
    useArticleDispatch,
    useArticleFormView,
    useArticleProgress,
    useArticleProgressDispatch,
    useArticleRoom,
} from '@mega/store';
import { omit } from 'lodash-es';
import { ArticleFormSettings } from './ArticleFormSettings';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import cn from 'classnames';
import { ArticleFormBody } from './ArticleFormBody';
import { ArticleEditActions } from './ArticleEditActions';
import { flattenObjKey } from '@mega/utils';
import {
    FiAlertCircle,
    FiAlertOctagon,
} from 'react-icons/fi';
import { typographyClasses } from '@mega/styles';
import { ApostrophSerializerMemo } from '@mega/core';
import { articleEditFormClasses } from './ArticleEdit.css';

const getLeadContent = (data: any) => {
    const leadBlock = Object.values(data)?.find(
        (item: any) => item?.type === 'LeadBlock',
    );
    const leadContent = Object.values(data)?.find(
        (item: any) =>
            item?.id ===
            (leadBlock as any).childrenElement[0],
    );
    if (
        typeof (leadContent as any).meta.content !==
        'string'
    ) {
        return '';
    }
    return flattenObjKey(
        JSON.parse((leadContent as any).meta.content),
        'text',
    )?.[0]?.trim();
};

export interface ArticleEditProps {
    extendForm?: ReactElement | ReactElement[];
}

export interface ArticleEditFormProps
    extends ArticleEditProps {
    initialData: ArticleForm;
}

const AutoSave: FC<{
    id: number;
}> = ({ id }) => {
    const { values } = useFormikContext<ArticleForm>();
    const {
        data,
        meta: {
            initialized,
            showDiff,
            wasChangedInit,
            wasProgress,
        },
    } = useArticleProgress();
    const { saveProgress, toggleOpenDiff } =
        useArticleProgressDispatch();
    const { currentEditor, readonlyUsers } =
        useArticleRoom();

    useEffect(() => {
        const omitedValues = omit(values, [
            'isPublish',
            'isSave',
            'isUnPublish',
        ]) as ArticleForm;
        if (initialized) {
            if (wasChangedInit) {
                if (showDiff) {
                    saveProgress(omitedValues);
                }
            } else {
                saveProgress(omitedValues);
            }
        }
    }, [values, initialized]);

    const ToggleProgress = () => {
        return showDiff ? (
            <Button
                label={'Закрыть прогресс'}
                key="buttonProgress"
                onClick={() => toggleOpenDiff(id)}
            ></Button>
        ) : (
            <Button
                label={'Посмотреть прогресс'}
                key="buttonProgress"
                onClick={() => toggleOpenDiff(id)}
            ></Button>
        );
    };
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '15px',
                width: '100%',
                marginBottom: '24px',
                flexWrap: 'wrap',
            }}
        >
            {wasChangedInit && (
                <div
                    className={
                        articleEditFormClasses.alertBox
                    }
                    style={{
                        border: '2px solid rgb(245, 124, 0)',
                    }}
                >
                    <span
                        className={
                            articleEditFormClasses.alertIcon
                        }
                        style={{
                            color: 'rgb(245, 124, 0)',
                        }}
                    >
                        <FiAlertOctagon />
                    </span>
                    <span
                        style={{
                            color: 'rgb(245, 124, 0)',
                        }}
                        className={cn(
                            typographyClasses.recipe({
                                size: '12',
                                weight: 'medium',
                            }),
                            articleEditFormClasses.alertTextColor,
                        )}
                    >
                        Пока вас не было, кто-то
                        отредактировал статью. Если
                        сохраните изменения, его правки
                        слетят
                    </span>
                </div>
            )}
            {showDiff && wasProgress && (
                <div
                    className={
                        articleEditFormClasses.alertBox
                    }
                >
                    <span
                        className={
                            articleEditFormClasses.alertIcon
                        }
                    >
                        <FiAlertCircle />
                    </span>
                    <span
                        className={cn(
                            typographyClasses.recipe({
                                size: '12',
                                weight: 'medium',
                            }),
                            articleEditFormClasses.alertTextColor,
                        )}
                    >
                        Изменения в статье хранятся только в
                        вашем браузере. Сохраните материал,
                        чтобы ничего не потерять
                    </span>
                </div>
            )}

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    flexGrow: '1',
                }}
            >
                {wasChangedInit && <ToggleProgress />}
            </div>
        </div>
    );
};

const ArticleEdit: FC<ArticleEditProps> = (props) => {
    const initialValues = useArticleFormView();
    const {
        data,
        meta: { showDiff },
    } = useArticleProgress();
    const { id } = useParams();
    if (data && data.id === id) {
        if (data.editor_data && showDiff) {
            let stringData = JSON.stringify(
                data.editor_data,
            );

            let keys = Object.keys(
                //@ts-ignore
                data?.editor_data?.data,
            ) as Array<string>;

            keys.forEach((value, index) => {
                stringData = stringData.replaceAll(
                    value,
                    `${uuidv4()}`,
                );
            });
            data.editor_data = JSON.parse(stringData);
            return (
                <ArticleEditForm
                    key={JSON.stringify(data)}
                    initialData={data}
                    {...props}
                />
            );
        }

        return (
            <ArticleEditForm
                key={JSON.stringify(initialValues)}
                initialData={initialValues}
                {...props}
            />
        );
    }
    return (
        <ArticleEditForm
            key={JSON.stringify(initialValues)}
            initialData={initialValues}
            {...props}
        />
    );
};

const ArticleEditForm: FC<ArticleEditFormProps> = ({
    initialData,
}) => {
    const { id } = useParams();
    const { isReadonly, isInit } = useArticleRoom();
    const {
        update,
        goToSite,
        transformFromFormViewToModel,
    } = useArticleDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleGoToSite = useCallback(() => {
        goToSite({
            options: {
                router: {
                    location,
                    navigate,
                },
            },
        });
    }, [location, navigate]);

    //@ts-ignore
    if (!initialData?.id) {
        return null;
    }
    return (
        <Formik
            validationSchema={Yup.object({
                title: Yup.string()
                    .min(
                        2,
                        'Это поле явно должно быть больше одного символа',
                    )
                    .required('Это поле обязательное'),
            })}
            initialValues={
                {
                    ...initialData,
                    isPublish: false,
                    isSave: false,
                    isUnPublish: false,
                } as ArticleFormExtend
            }
            enableReinitialize
            onSubmit={async (
                values,
                { setFieldValue, setFieldError },
            ) => {
                const form = omit(values, [
                    'isPublish',
                    'isSave',
                    'isUnPublish',
                ]);
                const readError = (error: {
                    status: number;
                    data: any;
                }) => {
                    const { data = {} } = error;
                    const firstKeyError = Object.keys(data);

                    if (firstKeyError.length !== 0) {
                        enqueueSnackbar(
                            data[firstKeyError[0]],
                            {
                                variant: 'error',
                            },
                        );
                    }
                    const [key, values] =
                        Object.entries(data)[0];
                    if (Array.isArray(values)) {
                        const firstError = values[0];
                        if (
                            typeof firstError === 'string'
                        ) {
                            setFieldError(key, firstError);
                        }
                    }
                };

                const model =
                    transformFromFormViewToModel(form);
                const editor_html = renderToString(
                    <ApostrophSerializerMemo
                        initData={values.editor_data}
                    />,
                );

                if (model.id) {
                    const hasLead = getLeadContent(
                        (values.editor_data as any).data,
                    );
                    if (values.isPublish) {
                        if (!hasLead) {
                            return enqueueSnackbar(
                                'Лид-абзац обязателен для опубликованной статьи',
                                {
                                    variant: 'error',
                                },
                            );
                        }
                        setFieldValue('isPublish', false);
                        const responce = await update({
                            query: {
                                id: model.id,
                            },
                            payload: {
                                ...model,
                                is_active: true,
                                editor_html,
                                lead: hasLead,
                            },
                        });

                        if (
                            responce?.isOk &&
                            //@ts-ignore
                            responce?.data
                        ) {
                            enqueueSnackbar(
                                'Статья опубликована',
                            );
                        }
                        if (
                            !responce?.isOk &&
                            responce?.error
                        ) {
                            readError(responce.error);
                        }
                    }
                    if (values.isUnPublish) {
                        setFieldValue('isUnPublish', false);
                        const responce = await update({
                            query: {
                                id: model.id,
                            },
                            payload: {
                                ...model,
                                is_active: false,
                                editor_html,
                            },
                        });
                        if (
                            responce?.isOk &&
                            //@ts-ignore
                            responce?.data
                        ) {
                            enqueueSnackbar(
                                'Статья статья снята с публикации',
                            );
                        }
                        if (
                            !responce?.isOk &&
                            responce?.error
                        ) {
                            readError(responce.error);
                        }
                    }
                    if (values.isSave) {
                        setFieldValue('isSave', false);
                        const responce = await update({
                            query: {
                                id: model.id,
                            },
                            payload: {
                                ...model,
                                editor_html,
                                lead: hasLead,
                            },
                        });
                        if (
                            responce?.isOk &&
                            //@ts-ignore
                            responce?.data
                        ) {
                            enqueueSnackbar(
                                'Статья обновлена',
                            );
                        }
                        if (
                            !responce?.isOk &&
                            responce?.error
                        ) {
                            readError(responce.error);
                        }
                    }
                }
            }}
        >
            <Form
                style={{
                    minHeight: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    pointerEvents: isReadonly
                        ? 'none'
                        : 'all',
                }}
            >
                {id && <AutoSave id={initialData.id} />}
                <FormLayout
                    actions={
                        isInit ? (
                            <ArticleEditActions
                                handleGoToSite={
                                    handleGoToSite
                                }
                            />
                        ) : (
                            <></>
                        )
                    }
                    settings={<ArticleFormSettings />}
                >
                    <ArticleFormBody />
                </FormLayout>
            </Form>
        </Formik>
    );
};

export { ArticleEdit };
