import * as CLIENT from '@mega/api';
import { Tag, TagCreate, TagUpdate } from '@mega/api';
import { createModel } from '@rematch/core';
import { DispatchEffectWithDependency } from '../type';

import type { RootModel } from '../rootModel';

export interface TagCreateEffect
    extends DispatchEffectWithDependency<
        Pick<TagCreate, 'title'>
    > {}

export interface TagUpdateEffect
    extends DispatchEffectWithDependency<TagUpdate> {
    payload: any;
    query: {
        id: number;
    };
}

const tag = createModel<RootModel>()({
    name: 'tag',
    state: {} as Tag,
    reducers: {
        put: (state, payload: Tag) => {
            return { ...payload };
        },
    },
    effects: (dispatch) => ({
        get: async (params: { id: number }, store) => {
            try {
                const response =
                    await CLIENT.tags.getById?.(
                        {
                            ...params,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.tag.put(response.data);
                }
            } catch (e) {
                if (
                    CLIENT.tags.getById &&
                    e instanceof CLIENT.tags.getById.Error
                ) {
                    const error = e.getActualType();
                    switch (error.status) {
                        case 401: {
                            return error.data;
                        }
                    }
                }
            }
        },
        create: async (
            { payload, options }: TagCreateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.tags?.create?.(
                        {
                            ...payload,
                            site: store.site.site?.id || '',
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    if (options?.router) {
                        const { navigate, location } =
                            options.router;
                        navigate(
                            `/${
                                store.site.site?.id || ''
                            }/tag/change/${
                                response.data.id
                            }`,
                            {
                                state: location,
                            },
                        );
                    }
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar(
                            'Новый тег добавлен',
                            {
                                variant: 'success',
                            },
                        );
                    }
                }
            } catch (e) {
                if (
                    CLIENT.tags.create &&
                    e instanceof CLIENT.tags.create.Error
                ) {
                    const error = e.getActualType();

                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            error.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                    return error.data;
                }
            }
        },
        update: async (
            { query, payload, options }: TagUpdateEffect,
            store,
        ) => {
            try {
                const response =
                    await CLIENT.tags?.updatePartialById?.({
                        data: payload,
                        id: query.id.toString(),
                        headers: {
                            'X-Site':
                                store.site.site?.id || '',
                        },
                    });
                if (
                    response?.data &&
                    response.status == 200
                ) {
                    dispatch.tag.put(response.data);
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;
                        enqueueSnackbar('Тег обновлен');
                    }
                } else {
                    if (options?.notifier) {
                        const { enqueueSnackbar } =
                            options.notifier;

                        const errormsgs = Object.values(
                            response.data,
                        );

                        errormsgs?.map((msg) => {
                            enqueueSnackbar(msg as string, {
                                variant: 'error',
                            });
                        });
                    }
                }
            } catch (e) {
                return e;
            }
        },
        remove: async (id: number, store) => {
            const query = store.tags.query;

            try {
                const response =
                    await CLIENT?.tags?.deleteById?.(
                        {
                            id,
                        },
                        {
                            headers: {
                                'X-Site':
                                    store.site.site?.id ||
                                    '',
                            },
                        },
                    );
                if (response?.ok) {
                    dispatch.tags.get(query);
                }
            } catch {}
        },
    }),
});

export { tag };
export default tag;
