import React, { useEffect, useReducer } from 'react';

import { Search } from '@mega/icons';
import {
    useArticlesDispatch,
    useUserInfo,
} from '@mega/store';
import { Input } from '@mega/ui';
import { Form, Formik } from 'formik';
import { MyArticles } from '@mega/core';
import { searchRecipe } from './ArticlesFilters.css';
import { useArticleFilters } from './useArticleFilters';
import { FilterPanel } from './FilterPanel';
import { FilterDisplay } from './ActiveFilter/FilterDisplay';
import { handleSelectTagConfig } from './util';
import { Options } from '@mega/ui/src/components/Inputs/types';
import { isArray } from 'lodash-es';
import {
    useSearchParams,
    URLSearchParamsInit,
} from 'react-router-dom';
import { hasOwnProperty } from '@mega/utils';

export type Option = {
    label: string;
    value: string | number;
};

export type FiltersState = {
    currentTags: Options | null;
    authors: Option | null;
    category: Option | null;
    owner: Option | null;
    loading: boolean;
};

const initialFilterState: FiltersState = {
    currentTags: null,
    authors: null,
    category: null,
    owner: null,
    loading: false,
};

const ArticlesFilters = () => {
    const [searchParams, setSearchParams] =
        useSearchParams();
    const { search, jumpTo } = useArticlesDispatch();
    const { me } = useUserInfo();

    const [filterState, setFilterState] = useReducer(
        (
            filterState: FiltersState,
            newFilterState: Partial<FiltersState>,
        ): FiltersState => ({
            ...filterState,
            ...newFilterState,
        }),
        initialFilterState,
    );

    const {
        authorsByIdCallback,
        categoriesByIdCallback,
        tagsByIdCallback,
        ownerByIdCallback,
        urlParams,
        query,
    } = useArticleFilters();

    const authorQuery = query.authors;
    const categoryQuery = query.category;
    const tagsQuery = query.tags__in;
    const ownerQuery = query.owner;

    useEffect(() => {
        const fetchData = async () => {
            if (
                !filterState.authors?.value &&
                query.authors
            ) {
                const authors = await authorsByIdCallback(
                    +query.authors,
                    1,
                );
                setFilterState({ authors: authors[0] });
            }

            if (
                !filterState.category?.value &&
                query.category
            ) {
                const category =
                    await categoriesByIdCallback(
                        +query.category,
                        1,
                    );
                setFilterState({ category: category[0] });
            }

            if (!filterState.owner?.value && query.owner) {
                const owner = await ownerByIdCallback(
                    +query.owner,
                    1,
                );
                setFilterState({ owner: owner[0] });
            }

            if (
                !filterState.currentTags &&
                query.tags__in
            ) {
                const tags = await tagsByIdCallback(
                    query.tags__in,
                    1,
                );
                if (
                    query.tags__in.split(',').includes('0')
                ) {
                    const withNoTag = [
                        { label: 'Без тега', value: 0 },
                        ...tags,
                    ];
                    setFilterState({
                        currentTags: withNoTag,
                    });
                } else {
                    setFilterState({ currentTags: tags });
                }
            }
        };

        fetchData();
    }, [authorQuery, categoryQuery, tagsQuery, ownerQuery]);

    useEffect(() => {
        // Задаем значения, если присутствуют в get параметрах.
        // Необходимо перечислить все фильтры, которые будут обрабатываться
        search({
            //@ts-ignore
            ['is_active']: urlParams
                .get('is_active')
                ?.toString(),
            ['pub_date__lte']: urlParams
                .get('pub_date__lte')
                ?.toString(),
            ['pub_date__gte']: urlParams
                .get('pub_date__gte')
                ?.toString(),
            ['page']: 1,
            ['ordering']: urlParams
                .get('ordering')
                ?.toString(),
            ['tags__in']: urlParams
                .get('tags__in')
                ?.toString(),
            ['category']: urlParams
                .get('category')
                ?.toString(),
            ['authors']: urlParams
                .get('authors')
                ?.toString(),
            ['owner']: urlParams.get('owner')?.toString(),
            ['is_feed_hidden']: urlParams
                .get('is_feed_hidden')
                ?.toString(),
            ['is_theme']: urlParams
                .get('is_theme')
                ?.toString(),
            ['search']: urlParams.get('search')?.toString(),
            ['is_gov_contract']: urlParams.get('is_gov_contract')?.toString(),
        });
    }, []);

    const currentUser = {
        label: me?.username || '',
        value: me?.author || '',
    };

    const cb = (
        prevInit: URLSearchParams,
    ): URLSearchParamsInit => {
        prevInit.set('page', '1');
        return prevInit.toString();
    };

    const resetPaginatorToFirstPage = () => {
        setSearchParams(cb);
        jumpTo(1);
    };

    const handleSelect = (
        type: string,
        e: Option | undefined,
    ) => {
        if (hasOwnProperty(filterState, type))
            setFilterState({
                [`${type}`]: e,
                loading: false,
            });
        resetPaginatorToFirstPage();

        search({
            [type]: e?.value || undefined,
        });
    };

    const handleSelectTag = (
        action: string,
        tags: Options | undefined,
    ) => {
        let type = 'tags__in';

        const config = handleSelectTagConfig(
            tags,
            filterState.currentTags?.length,
            action,
            query,
        );

        resetPaginatorToFirstPage();

        switch (true) {
            case config.add.initial:
                if (isArray(tags)) {
                    const tagStringRequest = tags
                        .map((option) => option.value)
                        .join(',');

                    search({
                        [type]: tagStringRequest,
                    });
                }
                break;

            case config.delete.initial:
                if (config.delete.last && tags) {
                    search({
                        [type]: undefined,
                    });
                    const filteredTags =
                        filterState.currentTags?.filter(
                            (t) =>
                                t.value !== tags[0].value,
                        );
                    setFilterState({
                        currentTags: filteredTags,
                    });
                } else if (tags) {
                    let tagStringRequest = query.tags__in
                        ?.split(',')
                        .filter(
                            (tag) =>
                                tag !==
                                String(tags[0].value),
                        );

                    search({
                        [type]: tagStringRequest?.join(','),
                    });
                    const filteredTags =
                        filterState.currentTags?.filter(
                            (t) =>
                                t.value !== tags[0].value,
                        );
                    setFilterState({
                        currentTags: filteredTags,
                    });
                }
                break;

            case config.clear:
                search({
                    [type]: undefined,
                });
                break;
        }
    };

    return (
        <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
                <div
                    style={{
                        display: 'flex',
                        gap: '18px',
                    }}
                >
                    <MyArticles
                        current={filterState.authors?.value}
                        handleSelect={handleSelect}
                        value={currentUser}
                        setFilterState={setFilterState}
                    />
                    <div
                        className={searchRecipe({
                            hasText: !!query?.search,
                        })}
                    >
                        <Input
                            startIcon={<Search />}
                            color="secondary"
                            dimension="small"
                            borderRadius={'small'}
                            placeholderBase={'Поиск'}
                            value={query?.search || ''}
                            type="text"
                            onChange={(e) => {
                                search({
                                    search:
                                        e.target.value ||
                                        undefined,
                                });
                            }}
                            style={{
                                '--input-placeholder-color':
                                    '#282828',
                            }}
                        />
                    </div>
                    <FilterPanel
                        filterState={filterState}
                        setFilterState={setFilterState}
                        handleSelectTag={handleSelectTag}
                        handleSelect={handleSelect}
                        resetPaginatorToFirstPage={
                            resetPaginatorToFirstPage
                        }
                    />
                </div>
                <FilterDisplay
                    currentAuthor={filterState.authors}
                    currentCategory={filterState.category}
                    currentTags={filterState.currentTags}
                    owner={filterState.owner}
                    handleSelectTag={handleSelectTag}
                    handleSelect={handleSelect}
                />
            </Form>
        </Formik>
    );
};

export { ArticlesFilters };
export default ArticlesFilters;
