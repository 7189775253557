import React, { FC, useEffect, useState } from 'react';
import {
    Divider,
    Icon,
    IconButton,
    Input,
    List,
    ListItem,
    MainLayout as Layout,
    MainLayoutProps,
    Typography,
} from '@mega/ui';
import {
    useAuthDispatch,
    useAuthStore,
    useNotifyStore,
    useSettings,
    useSiteStore,
    useUserInfo,
} from '@mega/store';
import {
    ArrowDown,
    AutoGraph,
    Code,
    DashboardAlt,
    Draw,
    Gallery,
    Videocam,
} from '@mega/icons';
import {
    Link,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { paddingTBClasses } from '@mega/styles';
import { Badge, Modal } from '@mui/material';
import { sidebarClasses } from './sidebar.css';
import { AnimatePresence, motion } from 'framer-motion';
import { FiActivity, FiLogOut } from 'react-icons/fi';
import { MenuSites } from '@mega/core';

const InfounitNotify = () => {
    const { count } = useNotifyStore();
    return (
        <Badge badgeContent={count} color={'error'}>
            <FiActivity />
        </Badge>
    );
};

const iconsMap = {
    hostvideo: <Videocam />,
    article: <Draw />,
    infounittask: <InfounitNotify />,
    report: <AutoGraph />,
};

const Sidebar = ({
    clickItem,
    inModal = false,
    onChange,
    isSmall,
}: {
    clickItem?: (obj: {
        isModel: boolean;
        key: string;
    }) => void;
    inModal?: boolean;
    size?: boolean;
    onChange?: () => void;
    isSmall?: boolean;
}) => {
    let { permissionNavigation } = useSettings();
    const { sites } = useUserInfo();

    const { logout, getNavigation } = useAuthDispatch();
    const currentSite = useSiteStore();

    const list = {
        visible: {
            opacity: 1,
            transition: {
                delay: 0.2,
                duration: 1,
            },
        },
        initial: {
            opacity: inModal ? 1 : 0,
            transition: {
                delay: 0.2,
                duration: 1,
            },
        },
    };

    const [isOpenAccordion, setIsOpenAccordion] =
        useState(false);

    function handleAccordion() {
        setIsOpenAccordion(!isOpenAccordion);
    }

    const handleSidebar = () => {
        if (!isSmall && isOpenAccordion) {
            setIsOpenAccordion(false);
        }
        if (onChange) {
            onChange();
        }
    };

    const [valueInput, setValueInput] =
        useState<string>('');

    const searchSites = sites.filter(({ label }) => {
        return label
            .toLowerCase()
            .includes(valueInput.toLowerCase());
    });

    useEffect(() => {
        if (currentSite) {
            getNavigation();
        }
    }, [currentSite]);
    return (
        <div
            className={sidebarClasses.recipe({
                inModal,
            })}
        >
            <MenuSites
                value="sites_user"
                isOpened={isOpenAccordion}
                handleAccordion={handleAccordion}
                endIcon={
                    <Icon size="18">
                        <Code
                            style={{
                                transform: 'rotate(90deg)',
                            }}
                        />
                    </Icon>
                }
                handlerSidebar={onChange}
                isSmall={isSmall}
                label={
                    <AnimatePresence>
                        <motion.div
                            initial={'initial'}
                            animate={'visible'}
                            exit={{
                                opacity: 0,
                            }}
                            variants={list}
                            className={
                                sidebarClasses.labelAccordion
                            }
                        >
                            {currentSite?.label}
                        </motion.div>
                    </AnimatePresence>
                }
            >
                <>
                    {sites.length > 8 ? (
                        <motion.div
                            animate={{
                                padding:
                                    '0px 18px 0px 18px',
                                transition: {
                                    delay: 0.5,
                                },
                            }}
                            initial={{
                                padding: '0px 0px 0px 0px',
                            }}
                        >
                            <Input
                                color="secondary"
                                style={{
                                    background: '#2b2b2b',
                                    color: '#878787',
                                    borderColor: '#878787',
                                }}
                                placeholderBase="Поиск"
                                dimension="small"
                                value={valueInput || ''}
                                onChange={(e) => {
                                    setValueInput(
                                        e.target.value,
                                    );
                                }}
                            />
                        </motion.div>
                    ) : null}
                    <List
                        style={{
                            maxHeight: '300px',
                            overflow: 'scroll',
                        }}
                    >
                        {searchSites.map((item) => (
                            <ListItem
                                key={item.id}
                                label={
                                    <Link
                                        to={`/${item.id}`}
                                        className={
                                            sidebarClasses.linkNoStyle
                                        }
                                    >
                                        <span
                                            className={sidebarClasses.titleList(
                                                {
                                                    sitesList:
                                                        true,
                                                },
                                            )}
                                        >
                                            {item.label}
                                        </span>
                                    </Link>
                                }
                            />
                        ))}
                    </List>
                </>
            </MenuSites>
            <div
                style={{
                    display: 'flex',
                    height: isOpenAccordion
                        ? '24px'
                        : '43px',
                }}
            ></div>

            {!inModal && (
                <IconButton
                    className={sidebarClasses.iconButton}
                    onClick={handleSidebar}
                    classes={{
                        paper: {
                            color: 'gray',
                            variant: 'outlineFilled',
                        },
                    }}
                >
                    <ArrowDown
                        className={sidebarClasses.iconRecipe(
                            {
                                isActive: isSmall,
                            },
                        )}
                    />
                </IconButton>
            )}
            <Divider />
            <div
                className={[
                    paddingTBClasses.recipe({
                        size: '24',
                    }),
                ].join(' ')}
            >
                <List
                    className={sidebarClasses.listPosition}
                >
                    <>
                        {permissionNavigation.map(
                            (item) => {
                                if (item.model) {
                                    return (
                                        <ListItem
                                            key={item.title}
                                            isButton
                                            startIcon={
                                                //@ts-ignore
                                                iconsMap?.[
                                                    item
                                                        ?.model
                                                ] || (
                                                    <DashboardAlt />
                                                )
                                            }
                                            label={
                                                <AnimatePresence>
                                                    {!isSmall && (
                                                        <motion.div
                                                            initial={
                                                                'initial'
                                                            }
                                                            animate={
                                                                'visible'
                                                            }
                                                            exit={{
                                                                opacity: 0,
                                                            }}
                                                            variants={
                                                                list
                                                            }
                                                        >
                                                            <p
                                                                className={sidebarClasses.titleList(
                                                                    {
                                                                        sitesList:
                                                                            false,
                                                                    },
                                                                )}
                                                            >
                                                                {
                                                                    item.title
                                                                }
                                                            </p>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            }
                                            onClick={() => {
                                                clickItem?.(
                                                    {
                                                        isModel:
                                                            true,
                                                        key:
                                                            item?.model ||
                                                            '??',
                                                    },
                                                );
                                            }}
                                        />
                                    );
                                }
                                return (
                                    <ListItem
                                        key={item.title}
                                        isButton
                                        startIcon={
                                            item.title ===
                                            'Справочники' ? (
                                                <Gallery />
                                            ) : (
                                                <DashboardAlt />
                                            )
                                        }
                                        label={
                                            <AnimatePresence>
                                                {!isSmall && (
                                                    <motion.div
                                                        initial={
                                                            'initial'
                                                        }
                                                        animate={
                                                            'visible'
                                                        }
                                                        exit={{
                                                            opacity: 0,
                                                        }}
                                                        variants={
                                                            list
                                                        }
                                                    >
                                                        <p
                                                            className={sidebarClasses.titleList(
                                                                {
                                                                    sitesList:
                                                                        false,
                                                                },
                                                            )}
                                                        >
                                                            {
                                                                item.title
                                                            }
                                                        </p>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        }
                                        onClick={() => {
                                            clickItem?.({
                                                isModel:
                                                    false,
                                                key:
                                                    item?.key ||
                                                    '??',
                                            });
                                        }}
                                    />
                                );
                            },
                        )}
                        <div
                            className={sidebarClasses.divider(
                                { inModal },
                            )}
                        />
                        <ListItem
                            key={'exit'}
                            isButton
                            startIcon={<FiLogOut />}
                            label={
                                <AnimatePresence>
                                    {!isSmall && (
                                        <motion.div
                                            initial={
                                                'initial'
                                            }
                                            animate={
                                                'visible'
                                            }
                                            exit={{
                                                opacity: 0,
                                            }}
                                            variants={list}
                                        >
                                            <p
                                                className={sidebarClasses.titleList(
                                                    {
                                                        sitesList:
                                                            false,
                                                    },
                                                )}
                                            >
                                                Выход
                                            </p>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            }
                            onClick={() => logout()}
                        />
                    </>
                </List>
            </div>
        </div>
    );
};

const MainLayout: FC<MainLayoutProps> = (props) => {
    const { isAuth } = useAuthStore();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] =
        useState<string | null>(null);
    const currentSite = useSiteStore();

    let navigate = useNavigate();
    let location = useLocation();

    let { permissionNavigation } = useSettings();

    const handleClickItem = ({
        isModel,
        key,
    }: {
        isModel: boolean;
        key: string;
    }) => {
        if (isModel) {
            setModalIsOpen(false);
            setSelectedCategory(null);
            navigate(`${currentSite?.id}/${key}`, {
                replace: true,
                state: location,
            });
        } else {
            setModalIsOpen(true);
            setSelectedCategory(key);
        }
    };

    const categories = permissionNavigation.filter((item) =>
        item.hasOwnProperty('key'),
    );

    const currentCategory =
        selectedCategory &&
        categories.filter(
            (item) => item.key === selectedCategory,
        )[0];

    const [isSmallSidebar, setIsSmallSidebar] =
        useState<boolean>(true);

    const handle = () => {
        setIsSmallSidebar(!isSmallSidebar);
    };

    return (
        <Layout
            {...props}
            isSmallSidebar={isSmallSidebar}
            showDrawer={isAuth}
            drawer={
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '100vh',
                        position: 'relative',
                    }}
                >
                    <Sidebar
                        isSmall={isSmallSidebar}
                        onChange={handle}
                        clickItem={handleClickItem}
                    />

                    <Modal
                        open={modalIsOpen}
                        onClose={() =>
                            setModalIsOpen(false)
                        }
                    >
                        <div
                            style={{
                                display: 'flex',
                                width: 'min-content',
                                flexDirection: 'row',
                                height: '100%',
                            }}
                        >
                            <Sidebar
                                clickItem={handleClickItem}
                                inModal
                            />

                            <div
                                style={{
                                    width: '240px',
                                    height: '100%',
                                    maxHeight: '100vh',
                                    backgroundColor:
                                        '#1E1D1D',
                                    position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:
                                        'center',
                                }}
                            >
                                {currentCategory && (
                                    <List>
                                        {currentCategory?.children?.map(
                                            (item) => (
                                                <ListItem
                                                    key={
                                                        item.title
                                                    }
                                                    isButton
                                                    label={
                                                        <Typography
                                                            size="16"
                                                            key={
                                                                item.title
                                                            }
                                                        >
                                                            {
                                                                item.title
                                                            }
                                                        </Typography>
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            item.model
                                                        ) {
                                                            handleClickItem(
                                                                {
                                                                    isModel:
                                                                        true,
                                                                    key: item.model,
                                                                },
                                                            );
                                                        }
                                                    }}
                                                />
                                            ),
                                        )}
                                    </List>
                                )}
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        />
    );
};

export { MainLayout };
