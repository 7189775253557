import { createContext } from 'react';
import {
    createDispatchHook,
    createSelectorHook,
} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import {
    init,
    RematchDispatch,
    RematchRootState,
} from '@rematch/core';
import loadingPlugin, {
    ExtraModelsFromLoading,
} from '@rematch/loading';
import selectPlugin from '@rematch/select';
import { NotifySaga } from './Notify';
import { models, RootModel } from './rootModel';

export type FullModel = ExtraModelsFromLoading<
    RootModel,
    { type: 'full' }
>;

export const storeContext = createContext(undefined);
export const sagaMiddleware = createSagaMiddleware();

export const store = init<RootModel, FullModel>({
    name: '360/store',
    models,
    plugins: [
        loadingPlugin({ type: 'full' }),
        selectPlugin(),
    ],
    redux: {
        devtoolOptions: {
            actionSanitizer: (action) => action,
        },
        middlewares: [sagaMiddleware],
    },
});

export const useStoreSelector =
    //@ts-ignore
    createSelectorHook(storeContext);
export const useStoreDispatch =
    //@ts-ignore
    createDispatchHook(storeContext);

store.dispatch.auth.stageInit();
sagaMiddleware.run(NotifySaga);

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<
    RootModel,
    FullModel
>;
