import React, { useState } from 'react';
import {
    useInfounitTaskDispatch,
    useInfounittasksDispatch,
} from '@mega/store';
import { Button, LinkWrapper } from '@mega/ui';
import { InfounittaskArray as InfoUnitType } from '@mega/api';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { FiArrowLeft, FiLink } from 'react-icons/fi';
import { RejectUnitModal } from '../../../RejectUnitModal';
import { ArticleLinkModal } from '../../../ArticleLinkModal';
import { useSnackbar } from 'notistack';

const modalList = {
    rollback: 'rollback',
    articleLink: 'articleLink',
};

export interface ActionsCellProps extends InfoUnitType {
    page: number;
}

export const ActionsCell: React.FC<ActionsCellProps> = (
    props,
) => {
    const { article, id, user, info_unit, page, site } =
        props;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { createArticle } = useInfounittasksDispatch();
    const { update } = useInfounitTaskDispatch();
    const [openedModal, setOpenedModal] = useState<
        string | null
    >(null);
    const hasArticle = Boolean(article);

    const handleSubmit = async (articleId: number) => {
        if (id && info_unit.id) {
            await update({
                query: {
                    id,
                },
                payload: {
                    info_unit: info_unit.id,
                    id,
                    article: articleId,
                },
                options: {
                    notifier: {
                        enqueueSnackbar,
                    },
                    onSuccess: () => setOpenedModal(null),
                },
            });
        }
    };
    return (
        <>
            <div
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {hasArticle ? (
                    <LinkWrapper
                        url={
                            id && article && site
                                ? `/${site}/article/change/${article.id}`
                                : ''
                        }
                    >
                        <Button
                            label={'Перейти'}
                            animate="none"
                            classes={{
                                paper: hasArticle
                                    ? {
                                          color: 'gray',
                                      }
                                    : {
                                          color: 'dark',
                                      },
                            }}
                        />
                    </LinkWrapper>
                ) : (
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                        }}
                    >
                        <Tooltip
                            title={'Взять в работу'}
                            placement="top"
                        >
                            <span>
                                <Button
                                    label={'В работу'}
                                    animate="none"
                                    classes={{
                                        paper: {
                                            color: hasArticle
                                                ? 'gray'
                                                : 'blue',
                                        },
                                    }}
                                    onClick={async () => {
                                        if (id) {
                                            const resEffect =
                                                await createArticle(
                                                    `${id}`,
                                                );

                                            if (
                                                resEffect?.isOk
                                            ) {
                                                navigate(
                                                    `/${resEffect.data?.site?.id}/article/change/${resEffect.data?.id}`,
                                                );
                                            }
                                        }
                                    }}
                                />
                            </span>
                        </Tooltip>
                        {/* Решение до изменения логики этих отказов*/}
                        <Tooltip
                            title={
                                !!user?.id
                                    ? 'Снимите назначенного пользователя'
                                    : 'Отказаться'
                            }
                            placement="top"
                        >
                            <span>
                                <Button
                                    label={''}
                                    endIcon={
                                        <FiArrowLeft />
                                    }
                                    disabled={!!user?.id}
                                    animate="none"
                                    classes={{
                                        paper: {
                                            color: 'red',
                                        },
                                    }}
                                    onClick={() => {
                                        setOpenedModal(
                                            modalList.rollback,
                                        );
                                    }}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip
                            title={'Добавить ссылку'}
                            placement="top"
                        >
                            <span>
                                <Button
                                    label={''}
                                    endIcon={<FiLink />}
                                    disabled={!!user?.id}
                                    animate="none"
                                    classes={{
                                        paper: {
                                            color: 'blue',
                                            variant:
                                                'outline',
                                        },
                                    }}
                                    onClick={() => {
                                        setOpenedModal(
                                            modalList.articleLink,
                                        );
                                    }}
                                />
                            </span>
                        </Tooltip>
                    </div>
                )}
            </div>
            <RejectUnitModal
                id={info_unit?.id || null}
                smi={site}
                isOpen={openedModal === modalList.rollback}
                page={page}
                onClose={() => setOpenedModal(null)}
            />
            <ArticleLinkModal
                isOpen={
                    openedModal === modalList.articleLink
                }
                onClose={() => setOpenedModal(null)}
                onSubmit={handleSubmit}
            />
        </>
    );
};
