import { ArrowDown, Filter } from '@mega/icons';

import {
    Group,
    WithTrigger,
    Button,
    Icon,
    SelectSingleField,
} from '@mega/ui';
import { Options } from '@mega/ui/src/components/Inputs/types';
import React from 'react';
import { DateRangePicker } from '../../DatePicker';
import { FiltersState } from '../ArticlesFilters';
import { filterWrapper } from '../ArticlesFilters.css';
import { SelectMultiWithTrigger } from '../SelectMultiWithTrigger';
import { SelectWithTrigger } from '../SelectWithtrigger';
import { useArticleFilters } from '../useArticleFilters';
import { RootState, useStoreSelector } from '@mega/store';

interface IFilterPanelProps {
    filterState: FiltersState;
    setFilterState: React.Dispatch<Partial<FiltersState>>;
    handleSelectTag: (
        action: string,
        tags: Options | undefined,
    ) => void;
    handleSelect: (type: string, e: any) => void;
    resetPaginatorToFirstPage: () => void;
}

export const FilterPanel = ({
    filterState,
    setFilterState,
    handleSelectTag,
    handleSelect,
    resetPaginatorToFirstPage,
}: IFilterPanelProps) => {
    const {
        authorsCallback,
        categoriesCallback,
        tagsCallback,
        ownerCallback,
        setDateRange,
    } = useArticleFilters();

    const { config } = useStoreSelector(
        (store: RootState) => store.settings,
    );

    const ruleGovContract =
        config.article.change['is_gov_contract'];

    const hasRuleGovContract =
        typeof ruleGovContract !== 'undefined';

    const isAvailableGovContact =
        !hasRuleGovContract ||
        (hasRuleGovContract && ruleGovContract);

    return (
        <Group>
            <div className={filterWrapper}>
                <Filter />
                <WithTrigger
                    trigger={({ handleClick }) => (
                        <Button
                            classes={{
                                paper: {
                                    color: 'gray',
                                },
                            }}
                            size="large"
                            onClick={handleClick}
                            label="Статус"
                            endIcon={
                                <Icon size="18">
                                    <ArrowDown />
                                </Icon>
                            }
                        />
                    )}
                >
                    <SelectSingleField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'primary',
                            },
                        }}
                        menuIsOpen
                        name="is_active"
                        actionOnSelectedOption={(e) => {
                            if (e) {
                                handleSelect(
                                    'is_active',
                                    e,
                                );
                            }
                        }}
                        handleLoader={async () => [
                            {
                                label: 'Опубликована',
                                value: 'true',
                            },
                            {
                                label: 'Не опубликована',
                                value: 'false',
                            },
                        ]}
                    />
                </WithTrigger>
                <WithTrigger
                    trigger={({ handleClick }) => (
                        <Button
                            classes={{
                                paper: {
                                    color: 'gray',
                                },
                            }}
                            size="large"
                            onClick={handleClick}
                            label="Лента"
                            endIcon={
                                <Icon size="18">
                                    <ArrowDown />
                                </Icon>
                            }
                        />
                    )}
                >
                    <SelectSingleField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'primary',
                            },
                        }}
                        menuIsOpen
                        name="is_feed_hidden"
                        actionOnSelectedOption={(e) => {
                            if (e) {
                                handleSelect(
                                    'is_feed_hidden',
                                    e,
                                );
                            }
                        }}
                        handleLoader={async () => [
                            {
                                label: 'Вне ленты',
                                value: 'true',
                            },
                            {
                                label: 'В ленте',
                                value: 'false',
                            },
                        ]}
                    />
                </WithTrigger>
                <WithTrigger
                    trigger={({ handleClick }) => (
                        <Button
                            classes={{
                                paper: {
                                    color: 'gray',
                                },
                            }}
                            size="large"
                            onClick={handleClick}
                            label="Сортировка"
                            endIcon={
                                <Icon size="18">
                                    <ArrowDown />
                                </Icon>
                            }
                        />
                    )}
                >
                    <SelectSingleField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'primary',
                            },
                        }}
                        menuIsOpen
                        name="ordering"
                        actionOnSelectedOption={(e) => {
                            if (e) {
                                handleSelect('ordering', e);
                            }
                        }}
                        handleLoader={async () => [
                            {
                                label: 'По дате создания по убыванию',
                                value: '-created_at',
                            },
                            {
                                label: 'По дате создания по возрастанию',
                                value: 'created_at',
                            },
                        ]}
                    />
                </WithTrigger>
                <WithTrigger
                    trigger={({ handleClick }) => (
                        <Button
                            classes={{
                                paper: {
                                    color: 'gray',
                                },
                            }}
                            size="large"
                            onClick={handleClick}
                            label="По дате"
                            endIcon={
                                <Icon size="18">
                                    <ArrowDown />
                                </Icon>
                            }
                        />
                    )}
                >
                    <DateRangePicker
                        onChange={(e: Date[]) => {
                            resetPaginatorToFirstPage();
                            setDateRange(e);
                        }}
                    />
                </WithTrigger>

                <SelectWithTrigger
                    name={'category'}
                    label={'Рубрика'}
                    handleLoader={categoriesCallback}
                    actionOnSelectedOption={(e) => {
                        if (e) {
                            handleSelect('category', e);
                        }
                    }}
                />

                <SelectWithTrigger
                    name={'authors'}
                    label={'Автор'}
                    handleLoader={authorsCallback}
                    actionOnSelectedOption={(e) => {
                        if (e) {
                            handleSelect('authors', e);
                        }
                    }}
                />

                <SelectWithTrigger
                    name={'owner'}
                    label={'Создатель'}
                    handleLoader={ownerCallback}
                    actionOnSelectedOption={(e) => {
                        if (e) {
                            handleSelect('owner', e);
                        }
                    }}
                />

                <SelectMultiWithTrigger
                    name={'tags'}
                    label={'Тег'}
                    currentTag={
                        filterState.currentTags ?? undefined
                    }
                    handleLoader={tagsCallback}
                    actionOnSelectedOption={(e) => {
                        if (e) {
                            if (
                                e.length === 0 &&
                                filterState.currentTags ===
                                    null
                            )
                                return;

                            if (
                                e.length !==
                                filterState.currentTags
                                    ?.length
                            )
                                setFilterState({
                                    currentTags: e,
                                });
                            handleSelectTag('add', e);
                        }
                    }}
                />
                {isAvailableGovContact && (
                    <WithTrigger
                        trigger={({ handleClick }) => (
                            <Button
                                classes={{
                                    paper: {
                                        color: 'gray',
                                    },
                                }}
                                size="large"
                                onClick={handleClick}
                                label="Госконтракт"
                                endIcon={
                                    <Icon size="18">
                                        <ArrowDown />
                                    </Icon>
                                }
                            />
                        )}
                    >
                        <SelectSingleField
                            classes={{
                                paper: {
                                    color: 'dark',
                                },
                                select: {
                                    variant: 'primary',
                                },
                            }}
                            menuIsOpen
                            name="is_gov_contract"
                            actionOnSelectedOption={(e) => {
                                if (e) {
                                    handleSelect(
                                        'is_gov_contract',
                                        e,
                                    );
                                }
                            }}
                            handleLoader={async () => [
                                {
                                    label: 'Входит в Госконтракт',
                                    value: 'true',
                                },
                                {
                                    label: 'Не входит в Госконтракт',
                                    value: 'false',
                                },
                            ]}
                        />
                    </WithTrigger>
                )}
            </div>
        </Group>
    );
};
