import React, { useEffect, useState } from 'react';
import { ImgLoaderProps } from './ImgLoader.types';
import {
    DndFileLoader,
    FileViewer,
    getOptionsCopyrights,
} from '@mega/core';
import * as CLIENT from '@mega/api';
import { Article, definitions } from '@mega/api';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { imgLoaderClasses } from './ImgLoader.css';
import { FilesObject } from '../MediaPickerModal/types';
import { useSiteStore } from '@mega/store';

const { create, deleteById, updatePartialById } =
    CLIENT.images;

const msCopyright = 'Медиасток.рф';
export const ImgLoader: React.FC<ImgLoaderProps> = (
    props,
) => {
    const {
        type,
        handleLoad,
        noDelete,
        handleDelete,
        id,
        noData,
        fileSrc,
        description,
        copyright,
        isCover,
        sourceText,
        sourceUrl,
        isFieldCopyright = true,
        multiple = true,
        isFieldSourceUrl = true,
        isFieldDescription = true,
        isInfographic = false,
        hasCrop = false,
        accept,
        externalBlock,
        maxFileSizeMb,
    } = props;
    const { values } = useFormikContext<Article>();
    const site = useSiteStore();
    const [isLoading, setIsLoading] = useState(false);
    const [defaultCopyright, setDefaultCopyright] =
        useState<number | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleCopyrightLoader = getOptionsCopyrights({
        site: site?.id || '',
    });
    const onUpload = async (
        file:
            | File
            | {
                  image: string;
                  title: string;
                  description: string;
                  copyright?: number | null;
              },
    ) => {
        let res;
        let data;
        if (file instanceof File) {
            const formData = new FormData();
            formData.append('image', file);
            data = formData;
        } else {
            data = file;
        }
        setIsLoading(true);
        res = await create?.({
            data,
            type,
            setIsLoading,
            notifier: enqueueSnackbar,
        });

        if (res) {
            handleLoad(res.data);
        }
    };
    const onUpdate = async (
        file: Partial<definitions['AdminImage']>,
        id?: number,
        cover?: boolean,
    ) => {
        let res;

        setIsLoading(true);
        if (cover && id) {
            res = await updatePartialById?.({
                id,
                data: file,
                type,
                setIsLoading,
                notifier: enqueueSnackbar,
            });
            if (res) {
                handleLoad(res.data);
            }
        }
    };

    const updateDescription = async (desc: string) => {
        let res;
        if (id && desc !== description) {
            res = await updatePartialById?.({
                id,
                type,
                data: { description: desc },
            });
        }
        if (res) {
            handleLoad(res.data);
        }
    };

    const updateSource = async ({
        sourceText,
        copyright,
    }: {
        sourceText: string | null;
        copyright: number | null;
    }) => {
        let res;
        if (!id) return null;

        res = await updatePartialById?.({
            id,
            type,
            data: { source_text: sourceText, copyright },
        });

        if (res) {
            handleLoad(res.data);
        }
    };

    const updateSourceUrl = async (su: string | null) => {
        let res;

        if (id && su !== sourceUrl) {
            res = await updatePartialById?.({
                id,
                type,
                data: { source_url: su },
            });
        }
        if (res) {
            handleLoad(res.data);
        }
    };

    const onDelete = async () => {
        if (id) {
            !noDelete && (await deleteById?.({ id, type }));
            handleDelete && handleDelete(id);
        }
    };

    const getDefaultCopyright = async () => {
        const res = await handleCopyrightLoader(
            msCopyright,
            1,
        );
        const value = res?.[0]?.value;
        if (value) setDefaultCopyright(value);
        return res;
    };
    useEffect(() => {
        if (externalBlock) {
            getDefaultCopyright();
        }
    }, []);

    return (
        <DndFileLoader
            id={id}
            noData={noData}
            fileType={'image'}
            isCover={isCover}
            multiple={multiple}
            onUpload={onUpload}
            onUpdate={onUpdate}
            onDelete={onDelete}
            description={description}
            copyright={copyright}
            sourceText={sourceText}
            sourceUrl={sourceUrl}
            isLoading={isLoading}
            updateDesc={updateDescription}
            updateSource={updateSource}
            updateSU={updateSourceUrl}
            minWidth={590}
            minHeight={350}
            isFieldSourceUrl={isFieldSourceUrl}
            isFieldCopyright={isFieldCopyright}
            isFieldDescription={isFieldDescription}
            isInfographic={isInfographic}
            accept={accept}
            maxFileSizeMb={maxFileSizeMb}
            hasCrop={hasCrop}
        >
            <div
                className={imgLoaderClasses.contentWrapper}
            >
                <FileViewer
                    fileSrc={fileSrc}
                    description={description}
                />
                {externalBlock &&
                    React.cloneElement(externalBlock, {
                        handleSubmit: (
                            values: FilesObject,
                        ) => {
                            for (const [
                                key,
                                value,
                            ] of Object.entries(values)) {
                                if (value?.file?.src) {
                                    onUpload({
                                        image: value?.file
                                            ?.src,
                                        title: value.title,
                                        description:
                                            description ||
                                            '',
                                        copyright:
                                            defaultCopyright,
                                    });
                                }
                            }
                        },
                    })}
            </div>
        </DndFileLoader>
    );
};
