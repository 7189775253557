import React, { FC, ReactElement } from 'react';
import { ArticleInfoUnit } from '../ArticleInfoUnit';
import { useField, useFormikContext } from 'formik';
import {
    Accordion,
    Button,
    Row,
    CheckboxSwitchField,
    Indi,
    InputSelectMultiField,
    Paper,
    SelectSingleField,
    Stack,
    WithLabel,
    InputField as Input,
} from '@mega/ui';
import { Settings } from '@mega/icons';
import {
    getOptionsAuthors,
    getOptionsCategories,
    getOptionsRss,
    getOptionsTags,
} from '@mega/core';
import {
    RootState,
    useSiteStore,
    useStoreSelector,
} from '@mega/store';
import { definitions } from '@mega/api';
import { ArticleSuggestedTags } from '../ArticleSuggestedTags';
import { classes } from './ArticleFormSettings.css';
import { useSnackbar } from 'notistack';

const settingsList = [
    {
        name: 'is_autogrid',
        label: 'Автосетка',
    },
    {
        name: 'is_important',
        label: 'Важно',
    },
    {
        name: 'is_exclusive',
        label: 'Эксклюзивна',
    },
    {
        name: 'is_muted',
        label: 'Без рекламы',
    },
    {
        name: 'is_feed_hidden',
        label: 'Не отображать в лентах',
    },
    {
        name: 'is_noindex',
        label: 'Скрыть от поисковиков',
    },
    {
        name: 'show_cover_in_body',
        label: 'Не отображать обложку',
    },
    {
        name: 'is_gov_contract',
        label: 'Входит в Госконтракт',
    },
];

export const ArticleFormSettings = () => {
    const currentSite = useSiteStore();
    const [{ value }] = useField<boolean>({
        name: 'is_active',
    });
    const { values } = useFormikContext();
    const { config } = useStoreSelector(
        (store: RootState) => store.settings,
    );
    const hasTelegramPost =
        config.article.change.telegram_post;

    const isCategorySpecialProjects =
        values?.category && values?.category.id === 11;

    console.log(values, 'values');

    const { enqueueSnackbar } = useSnackbar();

    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                enqueueSnackbar('Ссылка скопирована.', {
                    variant: 'default',
                });
            })
            .catch(() => {
                enqueueSnackbar(
                    'Не удалось скопировать ссылку, попробуйте позже ',
                    { variant: 'error' },
                );
            });
    };
    return (
        <Stack gap="16">
            <ArticleInfoUnit />
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <Stack gap="18">
                    <Row gap="8">
                        <Indi
                            colors={
                                value
                                    ? 'success'
                                    : 'in_progress'
                            }
                            label={
                                value
                                    ? 'Опубликована'
                                    : 'Не опубликована'
                            }
                        />
                        <Button
                            size={'medium'}
                            label="Копировать ссылку"
                            classes={{
                                paper: {
                                    color: 'dark',
                                    variant: 'outline',
                                },
                            }}
                            onClick={() => {
                                copyToClipboard(
                                    values.url || '',
                                );
                            }}
                        />
                    </Row>

                    <Accordion
                        startIcon={<Settings />}
                        label={'Настройки'}
                        value="settings"
                        isOpened
                    >
                        <Stack gap="8">
                            {settingsList.map(
                                (item, id) => {
                                    const { name, label } =
                                        item;

                                    const currentRule =
                                        config.article
                                            .change[
                                            name as keyof definitions['AdminDashboardConfigArticleChange']
                                        ];

                                    const hasRule =
                                        typeof currentRule !==
                                        'undefined';

                                    const isAvailable =
                                        !hasRule ||
                                        (hasRule &&
                                            currentRule);
                                    if (!isAvailable)
                                        return null;
                                    return (
                                        <CheckboxSwitchField
                                            key={id}
                                            name={name}
                                            label={label}
                                        />
                                    );
                                },
                            )}
                        </Stack>
                    </Accordion>
                </Stack>
            </Paper>

            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel
                    id={'authors'}
                    title="Тип материала"
                >
                    <SelectSingleField
                        name={'article_type'}
                        placeholder={'Тип материала'}
                        handleLoader={async () => [
                            {
                                value: 'article',
                                label: 'Новость',
                            },
                            {
                                value: 'text',
                                label: 'Текст',
                            },
                        ]}
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                    />
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <>
                    <WithLabel
                        id={'category'}
                        isRequired
                        title="Рубрика"
                    >
                        <SelectSingleField
                            clearIcon={<></>}
                            isCannotRemovalbe={true}
                            name={'category'}
                            handleLoader={getOptionsCategories(
                                {
                                    site:
                                        currentSite?.id ||
                                        '',
                                },
                            )}
                            classes={{
                                paper: {
                                    color: 'dark',
                                },
                                select: {
                                    variant: 'secondary',
                                },
                            }}
                        />
                    </WithLabel>

                    {isCategorySpecialProjects && (
                        <WithLabel
                            id={'redirect_url'}
                            title="Редирект"
                        >
                            <Input
                                placeholderBase={() => (
                                    <span
                                        className={
                                            classes.placeholder
                                        }
                                    >
                                        Ссылка на источник
                                    </span>
                                )}
                                type="text"
                                name={'redirect_url'}
                                dimension="small"
                            />
                        </WithLabel>
                    )}
                </>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel id={'rss'} title="RSS каналы">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="rssfeeds"
                        handleLoader={getOptionsRss({
                            site: currentSite?.id || '',
                        })}
                    />
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel id={'tags'} title="Теги">
                    <>
                        <InputSelectMultiField
                            classes={{
                                paper: {
                                    color: 'dark',
                                },
                                select: {
                                    variant: 'secondary',
                                },
                            }}
                            name="tags"
                            handleLoader={getOptionsTags({
                                site: currentSite?.id || '',
                            })}
                        />
                        <ArticleSuggestedTags />
                    </>
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel id={'authors'} title="Авторы">
                    <InputSelectMultiField
                        classes={{
                            paper: {
                                color: 'dark',
                            },
                            select: {
                                variant: 'secondary',
                            },
                        }}
                        name="authors"
                        handleLoader={getOptionsAuthors({
                            site: currentSite?.id || '',
                        })}
                    />
                </WithLabel>
            </Paper>
            <Paper
                contentHeight
                color={'white'}
                variant="outline"
                classes={{
                    padding: {
                        size: '24x24',
                    },
                }}
            >
                <WithLabel
                    id={'telegram_post'}
                    title="Комментарии"
                >
                    <Input
                        placeholderBase={() => (
                            <span
                                className={
                                    classes.placeholder
                                }
                            >
                                Ссылка на пост в Telegram
                            </span>
                        )}
                        type="text"
                        name={'telegram_post'}
                        dimension="small"
                        disabled={!hasTelegramPost}
                    />
                </WithLabel>
            </Paper>
        </Stack>
    );
};
