import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgHtmlcode = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        height="1em"
        viewBox="0 0 32 32"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <path
            d="M19 13v-3l-6-7H2.003A2.005 2.005 0 0 0 0 5.007v22.986A2 2 0 0 0 1.997 30h15.006A1.999 1.999 0 0 0 19 28.01V26h10.006A2.996 2.996 0 0 0 32 23.001V16A2.997 2.997 0 0 0 29.006 13H19Zm-1 13v2.007A.997.997 0 0 1 17 29H2c-.545 0-1-.446-1-.995V4.995c0-.54.446-.995.996-.995H12v4.994c0 1.12.894 2.006 1.998 2.006H18v2H5.994A2.996 2.996 0 0 0 3 15.999V23A2.997 2.997 0 0 0 5.994 26H18ZM13 4.5v4.491c0 .557.45 1.009.997 1.009H17.7L13 4.5ZM6.007 14C4.9 14 4 14.9 4 15.992v7.016A2 2 0 0 0 6.007 25h22.986C30.1 25 31 24.1 31 23.008v-7.016A2 2 0 0 0 28.993 14H6.007ZM29 22v1h-5v-7h1v6h4Zm-15-5v6h1v-6h2v-1h-5v1h2Zm-4 2v-3h1v7h-1v-3H7v3H6v-7h1v3h3Zm10.5 0L19 16h-1v7h1v-5l1 2h1l1-2v5h1v-7h-1l-1.5 3Z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(SvgHtmlcode);
export default ForwardRef;
