import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';
import {
    $getRoot,
    COMMAND_PRIORITY_EDITOR,
    createCommand,
    LexicalCommand,
    LexicalNode,
    TextNode,
    $isParagraphNode,
    $getNodeByKey,
} from 'lexical';

import { $isLinkNode } from '@lexical/link';
import Typograf from 'typograf';
import { useApostrophStore } from '@mega/store';
import { getTypographedText } from '@mega/utils';
import { flatten } from 'lodash-es';
const TYPOGRAPHY_COMMAND: LexicalCommand<string> =
    createCommand();
const tp = new Typograf({ locale: ['ru', 'en-US'] });
tp.disableRule('common/symbols/cf');

export const getTypographTextOld = (
    textNodes: Array<TextNode> | Array<LexicalNode>,
) => {
    return textNodes?.map((node, index) => {
        if (node?.__text) {
            let typographedText = tp.execute(
                node.getTextContent(),
            );

            const nextIndex =
                textNodes[index + 1]?.__type === 'link'
                    ? index + 2
                    : index + 1;

            const nextTypographed =
                textNodes.length - 1 > index
                    ? tp.execute(
                          textNodes[
                              nextIndex
                          ].getTextContent(),
                      )
                    : '';

            const nextIsMark = /[.,!-?]/gm.test(
                nextTypographed.trim()?.[0],
            );

            typographedText =
                getTypographedText(typographedText);

            node.setTextContent(
                `${typographedText}${
                    index === textNodes.length - 1 ||
                    nextIsMark
                        ? ''
                        : ' '
                }`,
            );
            return node;
        }
    });
};

const notNeedSpace = [
    ' ',
    ' ',
    '.',
    ',',
    ':',
    ';',
    '!',
    '?',
    '%',
    '#',
    '@',
    '"',
    "'",
    '«',
    '»',
];

export const getTypographText = (
    textNodes: Array<TextNode | LexicalNode>,
) => {
    const groupedTextNodeByParagraph = Object.values(
        textNodes.reduce<{
            [key: string]: Array<TextNode | LexicalNode>;
        }>((acc, item) => {
            if (!item?.__parent) {
                return acc;
            }
            let parent = $getNodeByKey(item.__parent);

            const $isHeadingNode =
                parent?.__type === 'heading';

            const $isListitemNode =
                parent?.__type === 'listitem';

            if ($isListitemNode && parent) {
                return {
                    ...acc,
                    [parent.__key]: [
                        ...(acc?.[parent.__key] || []),
                        item,
                    ],
                };
            }
            if ($isHeadingNode && parent) {
                return {
                    ...acc,
                    [parent.__key]: [
                        ...(acc?.[parent.__key] || []),
                        item,
                    ],
                };
            }

            while (!$isParagraphNode(parent)) {
                if (!parent?.__parent) {
                    return acc;
                }
                parent = $getNodeByKey(parent.__parent);
            }
            return {
                ...acc,
                [parent.__key]: [
                    ...(acc?.[parent.__key] || []),
                    item,
                ],
            };
        }, {}),
    );
    //@ts-ignore
    const newTextContent = flatten(
        groupedTextNodeByParagraph.map((textNodes) => {
            const newStr = textNodes.reduce<string>(
                (acc, node) => {
                    const parrentNode = node?.__parent
                        ? $getNodeByKey(node?.__parent)
                        : null;
                    const textContent =
                        node.getTextContent();
                    const $isHeadingNode =
                        parrentNode?.__type === 'heading';

                    if ($isHeadingNode) {
                        if (acc) {
                            return `${acc}\\##\\${textContent}`;
                        } else {
                            return `${textContent}`;
                        }
                    }

                    if ($isParagraphNode(parrentNode)) {
                        if (acc) {
                            return `${acc}\\##\\${textContent}`;
                        } else {
                            return `${textContent}`;
                        }
                    }
                    if ($isLinkNode(parrentNode)) {
                        if (acc) {
                            const newstr = tp
                                .execute(
                                    `${
                                        textContent.charAt(
                                            0,
                                        ) === '"'
                                            ? '  '
                                            : ''
                                    }${textContent}${
                                        textContent.charAt(
                                            textContent.length -
                                                1,
                                        ) === '"'
                                            ? '  '
                                            : ''
                                    }`,
                                )
                                .replaceAll('  ', '');
                            return `${acc}\\##\\${newstr}`;
                        } else {
                            return `${textContent}`;
                        }
                    }
                    if (acc) {
                        return `${acc}\\##\\${textContent}`;
                    } else {
                        return `${textContent}`;
                    }
                },
                '',
            );

            const typographedString = tp
                .execute(newStr)
                .replaceAll('  ', ' ')
                .replaceAll('   ', ' ')
                .trim();

            const newTextContent =
                typographedString.split('\\##\\');

            let test = newTextContent.map((item, index) =>
                item.replaceAll('  ', ' '),
            );

            textNodes?.map((node, index) => {
                if (node?.__text) {
                    const pref = test?.[index - 1] || '';
                    const next = test?.[index + 1] || '';
                    let typographedText = test[index];

                    if (pref && next) {
                        const prefWithWhiteSpaceEnd =
                            notNeedSpace.includes(
                                pref[pref.length - 1],
                            );
                        const currentWithWhiteSpaceStart =
                            notNeedSpace.includes(
                                typographedText[0],
                            );
                        const currentWithWhiteSpaceEnd =
                            notNeedSpace.includes(
                                typographedText[
                                    typographedText.length -
                                        1
                                ],
                            );

                        const nextWithWhiteSpaceStart =
                            notNeedSpace.includes(next[0]);
                        if (
                            !prefWithWhiteSpaceEnd &&
                            !currentWithWhiteSpaceStart
                        ) {
                            typographedText =
                                ' ' + typographedText;
                        }
                        if (
                            !currentWithWhiteSpaceEnd &&
                            !nextWithWhiteSpaceStart
                        ) {
                            typographedText =
                                typographedText + ' ';
                        }
                        test[index] = typographedText;
                    }

                    node.setTextContent(typographedText);
                    return node;
                }
            });
            return textNodes;
        }),
    );
    return newTextContent;
};

export function TypographAllPlugin() {
    const [editor] = useLexicalComposerContext();
    const { isFormatted } = useApostrophStore();

    useEffect(() => {
        editor.registerCommand(
            TYPOGRAPHY_COMMAND,
            () => {
                const textNodes =
                    $getRoot().getAllTextNodes();
                getTypographText(textNodes);
                return false;
            },
            COMMAND_PRIORITY_EDITOR,
        );
    }, []);

    useEffect(() => {
        if (isFormatted) {
            editor.dispatchCommand(TYPOGRAPHY_COMMAND, '');
        }
    }, [isFormatted]);

    return null;
}
