import * as React from 'react';
import { SVGProps, Ref, forwardRef } from 'react';
const SvgEraser = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={ref}
        {...props}
    >
        <g clipPath="url(#eraser_svg__a)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.758 13.944c-.31.311-.59.59-.847.831H15.3a.525.525 0 0 1 0 1.05H6.9l-.038-.001c-.493-.02-.91-.232-1.321-.545-.411-.314-.87-.773-1.432-1.335l-.053-.053c-.562-.562-1.021-1.02-1.335-1.432-.328-.43-.546-.868-.546-1.393 0-.524.218-.962.546-1.392.314-.411.773-.87 1.335-1.432l4.186-4.186c.562-.562 1.021-1.021 1.432-1.335.43-.328.869-.546 1.393-.546s.962.218 1.392.546c.411.314.87.773 1.432 1.335l.053.053c.562.562 1.021 1.02 1.335 1.432.328.43.546.868.546 1.392 0 .525-.218.963-.546 1.393-.314.411-.773.87-1.335 1.432l-4.186 4.186Zm-.8-9.12c.595-.594 1.005-1.002 1.353-1.268.337-.257.553-.331.755-.331.203 0 .42.074.756.33.349.267.758.675 1.353 1.27s1.003 1.004 1.27 1.353c.256.336.33.553.33.755 0 .203-.074.42-.33.756-.267.348-.675.758-1.27 1.353l-2.935 2.935L6.023 7.76l2.935-2.935Zm-2.025 9.951c.203 0 .42-.074.756-.33.348-.267.758-.675 1.353-1.27l.455-.455L5.28 8.503l-.455.455c-.595.595-1.003 1.005-1.27 1.353-.256.336-.33.553-.33.755 0 .203.074.42.33.756.267.349.675.758 1.27 1.353s1.004 1.003 1.353 1.27c.336.256.553.33.755.33Z"
                fill="#F4F4F4"
            />
        </g>
        <defs>
            <clipPath id="eraser_svg__a">
                <path
                    fill="#fff"
                    transform="translate(2 2)"
                    d="M0 0h14v14H0z"
                />
            </clipPath>
        </defs>
    </svg>
);
const ForwardRef = forwardRef(SvgEraser);
export default ForwardRef;
