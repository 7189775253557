import React, { useEffect, useState } from 'react';
import * as CLIENT from '@mega/api';
import { Article, definitions } from '@mega/api';

import { useFormikContext } from 'formik';
import { ImgLoader, MediaLoaderWithMS } from '@mega/core';
import { VideoPicker } from '../VideoPicker';
import {
    removeFromFileArray,
    updateFileArray,
} from '@mega/utils/src/updateFileArrays';
import {
    RootState,
    useMediaDispatch,
    useStoreSelector,
} from '@mega/store';

export interface ArticleCoverProps {
    isVideo?: boolean;
    id: string;
    isFieldSourceUrl?: boolean;
    isFieldCopyright?: boolean;
    isFieldDescription?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

const { getPreviewById } = CLIENT.images;

export const ArticleCover: React.FC<ArticleCoverProps> = (
    props,
) => {
    const { config } = useStoreSelector(
        (store: RootState) => store.settings,
    );

    const {
        isVideo,
        id,
        isFieldSourceUrl,
        isFieldCopyright,
        isFieldDescription,
    } = props;
    const isAvailable =
        !!config.article.change[
            id as keyof definitions['AdminDashboardConfigArticleChange']
        ];
    const { values, setFieldValue } =
        useFormikContext<Article>();
    const { setIsLoading } = useMediaDispatch();
    const [preview, setPreview] = useState<
        Record<string, string>
    >({});

    const field = isVideo ? 'main_video' : 'cover';
    const arrayField = isVideo ? 'videos' : 'images';

    const handleDelete = (id?: number) => {
        if (id) {
            removeFromFileArray({
                ids: [id],
                field: arrayField,
                values,
                setFieldValue,
            });
        }
        setFieldValue(field, null);
    };

    const handleCoverUpdate = (
        res: definitions['AdminImage'],
    ) => {
        updateFileArray({
            files: [res],
            field: arrayField,
            values,
            setFieldValue,
        });
        setFieldValue(field, res);
    };

    const fetchPreviewImage = async (
        id: number,
        fp_x?: number,
        fp_y?: number,
    ) => {
        const { data } = (await getPreviewById?.({
            id,
            fp_x: fp_x || 0.5,
            fp_y: fp_y || 0.5,
        })) as any;
        setPreview(data);
    };

    useEffect(() => {
        if (values.cover?.id) {
            const { id, fp_x, fp_y } = values.cover;
            fetchPreviewImage(id, fp_x, fp_y);
        }
    }, [values.cover]);

    useEffect(() => {
        setIsLoading({
            isLoading: false,
        });
    }, [values.images]);

    if (isVideo) {
        return (
            <MediaLoaderWithMS
                isHidden={!isAvailable}
                type={'video'}
                onOpen={props.onOpen}
                onClose={props.onClose}
            >
                <VideoPicker
                    fileSrc={
                        values.main_video?.file_link ||
                        //@ts-ignore
                        values.main_video?.video
                    }
                    poster={values.main_video?.frame}
                    description={values.cover?.description}
                    id={values.main_video?.id}
                    handleLoad={handleCoverUpdate}
                    handleDelete={handleDelete}
                />
            </MediaLoaderWithMS>
        );
    }

    const fileSrc =
        values.cover?.id &&
        (preview['16x9'] ||
            // @ts-ignore
            values.cover?.src ||
            values.cover?.image);

    return (
        <MediaLoaderWithMS
            isHidden={!isAvailable}
            type={'image'}
            onOpen={props.onOpen}
            onClose={props.onClose}
        >
            <ImgLoader
                noDelete
                fileSrc={fileSrc}
                description={values.cover?.description}
                id={values.cover?.id}
                //@ts-ignore
                copyright={values.cover?.copyright}
                sourceText={values.cover?.source_text}
                sourceUrl={values.cover?.source_url}
                isCover={true}
                hasCrop
                multiple={false}
                handleLoad={handleCoverUpdate}
                handleDelete={handleDelete}
                isFieldSourceUrl={isFieldSourceUrl}
                isFieldCopyright={isFieldCopyright}
                isFieldDescription={isFieldDescription}
            />
        </MediaLoaderWithMS>
    );
};
