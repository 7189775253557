import React, { useEffect, useRef, useState } from 'react';
import { fileViewerClasses } from '../FileViewer.css';
import { motion } from 'framer-motion';
import { PlayCircle } from '@mega/icons';

interface ViewProps {
    isVideo?: boolean;
    fileSrc?: string;
    description?: string;
    videoPoster?: string;
}

export const View: React.FC<ViewProps> = (props) => {
    const { isVideo, fileSrc, videoPoster, description } =
        props;

    const [isPlay, setIsPlay] = useState(false);

    const vidRef = useRef<HTMLVideoElement>(null);
    const handlePlayVideo = () => {
        setIsPlay(true);
    };

    useEffect(() => {
        if (isPlay) {
            vidRef.current && vidRef.current.play();
        }
    }, [isPlay]);

    useEffect(() => {
        setIsPlay(false);
    }, [fileSrc]);

    if (isVideo) {
        return (
            <div className={fileViewerClasses.videoWrapper}>
                <video
                    ref={vidRef}
                    src={fileSrc}
                    className={fileViewerClasses.image}
                    muted
                    controls
                    poster={videoPoster || ''}
                    autoPlay={false}
                />
                {!isPlay && videoPoster && (
                    <>
                        <img
                            className={
                                fileViewerClasses.videoPreview
                            }
                            src={videoPoster}
                            alt={description || 'preview'}
                        />
                        <div
                            className={
                                fileViewerClasses.playButton
                            }
                        >
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                transition={{
                                    duration: 0.2,
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    videoPoster &&
                                        handlePlayVideo();
                                }}
                            >
                                <PlayCircle />
                            </motion.div>
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            <img
                className={fileViewerClasses.image}
                src={fileSrc}
                alt={description || 'cover'}
            />
        </>
    );
};
