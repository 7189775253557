import React, { ReactElement } from 'react';
import { useEditor } from '@apostroph/store';
import { maybePluginKeys } from '../../plugins/types';
import { IconButton, Menu, Row } from '@mega/ui';
import {
    Add,
    Ads,
    FormatQuote,
    Gallery,
    HorizontalRule,
    Htmlcode,
    Image,
    Subject,
    Update,
    Videocam,
    Graph,
    Reference,
} from '@mega/icons';
import { FiEdit } from 'react-icons/fi';

import { Tooltip } from '@mui/material';
import { RootState, useStoreSelector } from '@mega/store';

type EditorConfig = {
    tooltip: string;
    name: maybePluginKeys;
    icon: ReactElement;
    disalable?: boolean;
};
const editorConfig: Array<EditorConfig> = [
    {
        tooltip: 'Текст',
        name: 'MILK',
        icon: <Subject />,
    },
    {
        tooltip: 'Цитата',
        name: 'BLOCKQUOTE',
        icon: <FormatQuote />,
    },
    {
        tooltip: 'Видео',
        name: 'VIDEO',
        icon: <Videocam />,
    },
    {
        tooltip: 'Картинка',
        name: 'IMG',
        icon: <Image />,
    },
    {
        tooltip: 'Галерея',
        name: 'GALLERY',
        icon: <Gallery />,
    },
    {
        tooltip: 'Инфографика',
        name: 'INFOGRAPH',
        icon: <Graph />,
    },
    {
        tooltip: 'Блок Справочное',
        name: 'MAIN',
        icon: <Reference />,
    },
    {
        tooltip: 'HTML код',
        name: 'HTML_TEXT',
        icon: <Htmlcode />,
    },
    {
        tooltip: 'Разделитель',
        name: 'HORIZONTAL_LINE',
        icon: <HorizontalRule />,
    },
    {
        tooltip: 'ADS источник',
        name: 'ADS_SOURCE',
        icon: <Ads />,
    },
    {
        tooltip: 'Карточка',
        name: 'SECTION',
        icon: <FiEdit />,
        disalable: true,
    },
    {
        tooltip: 'В процессе',
        name: 'IN_PROGRESS',
        icon: <Update />,
    },
];
export const EditorControls: React.FC<{
    id?: string;
    parentId?: string | null;
    disableSection?: boolean;
}> = (props) => {
    const { id, parentId, disableSection = false } = props;
    const {
        editor,
        getTemplate,
        handleCreateElement,
        handleInsertElement,
    } = useEditor();
    const { config } = useStoreSelector(
        (store: RootState) => store.settings,
    );
    const contentBlock =
        parentId ||
        editor?.data?.[editor?.items?.[1]]?.id ||
        null;

    let handleCloseAction: Function;

    const handleCreate =
        (templateKey: maybePluginKeys) => () => {
            if (contentBlock) {
                const template = getTemplate(templateKey);
                if (template) {
                    if (id) {
                        handleInsertElement(
                            template,
                            id,
                            contentBlock,
                        );
                    } else {
                        handleCreateElement(
                            template,
                            contentBlock,
                        );
                    }

                    handleCloseAction();
                }
            }
        };
    return (
        <Menu
            style={{ padding: '8px' }}
            paddingTB={{ size: 'none' }}
            variant={'secondary'}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            gap="8px"
            trigger={({ handleClick, handleClose }) => {
                handleCloseAction = handleClose;
                return (
                    <IconButton
                        type="button"
                        classes={{
                            paper: {
                                variant: 'dashed',
                            },
                        }}
                        onClick={handleClick}
                    >
                        <Add />
                    </IconButton>
                );
            }}
        >
            <Row gap="8">
                {editorConfig.map((item, id) => {
                    const {
                        tooltip,
                        name,
                        icon,
                        disalable,
                    } = item;

                    const isAvailable =
                        config.article.change.editor_data[
                            name
                        ];
                    if (
                        (disalable && disableSection) ||
                        !isAvailable
                    )
                        return null;
                    return (
                        <Tooltip
                            key={id}
                            title={tooltip}
                            placement="bottom"
                        >
                            <span>
                                <IconButton
                                    size={'medium'}
                                    type="button"
                                    color="secondary"
                                    onClick={handleCreate(
                                        name,
                                    )}
                                >
                                    {icon}
                                </IconButton>
                            </span>
                        </Tooltip>
                    );
                })}
            </Row>
        </Menu>
    );
};
