import { Models } from '@rematch/core';
import { user } from './User';
import { auth } from './Auth';
import { site } from './Site';
import {
    articles,
    article,
    articleRoom,
    articlesRoom,
    articleProgress,
    articleMultipost,
} from './Articles';
import { copyrights, copyright } from './CP';
import { apostroph } from './Apostroph';
import { media } from './Media';
import { videos, video } from './Videos';
import { tags, tag } from './Tags';
import { settings } from './Settings';
import { categories, category } from './Categories';
import { layout } from './Layout';
import { authors, author } from './Authors';
import {
    infounittasks,
    infounittask,
} from './Infounittask';
import { notify } from './Notify';
import {
    sourcesReports,
    sourcesReport,
} from './SourcesReports';
import { reposts, repost } from './Reposts';
import { papers, paper } from './Papers';
import { documents, document } from './Documents';
import { streams, stream } from './Streams';
import {
    adsBlock,
    adsHeadScript,
    adsBlocks,
    adsHeadScripts,
} from './ADS';
import {
    infiniteMix,
    infiniteMixList,
} from './InfinityMix';

import {
    bannedOrganization,
    bannedOrganizations,
} from './Bannedorganization';
import { mainGrid } from './MainGrid/mainGrid.store';
import { employees, employee } from './Employees';
import { stories, story } from './Stories';

export interface RootModel extends Models<RootModel> {
    user: typeof user;
    mainGrid: typeof mainGrid;
    auth: typeof auth;
    site: typeof site;
    articles: typeof articles;
    articlesRoom: typeof articlesRoom;
    article: typeof article;
    articleRoom: typeof articleRoom;
    articleProgress: typeof articleProgress;
    articleMultipost: typeof articleMultipost;
    tags: typeof tags;
    tag: typeof tag;
    categories: typeof categories;
    category: typeof category;
    authors: typeof authors;
    author: typeof author;
    videos: typeof videos;
    video: typeof video;
    layout: typeof layout;
    settings: typeof settings;
    apostroph: typeof apostroph;
    media: typeof media;
    infounittasks: typeof infounittasks;
    notify: typeof notify;
    copyrights: typeof copyrights;
    copyright: typeof copyright;
    reposts: typeof reposts;
    repost: typeof repost;
    sourcesReports: typeof sourcesReports;
    sourcesReport: typeof sourcesReport;
    infounittask: typeof infounittask;
    papers: typeof papers;
    paper: typeof paper;
    documents: typeof documents;
    document: typeof document;
    //@ts-ignore
    streams: typeof streams;
    stream: typeof stream;
    adsBlock: typeof adsBlock;
    adsBlocks: typeof adsBlocks;
    adsHeadScript: typeof adsHeadScript;
    adsHeadScripts: typeof adsHeadScripts;
    infiniteMix: typeof infiniteMix;
    infiniteMixList: typeof infiniteMixList;
    bannedOrganization: typeof bannedOrganization;
    bannedOrganizations: typeof bannedOrganizations;
    stories: typeof stories;
    story: typeof story;
}

export const models: RootModel = {
    auth,
    user,
    site,
    articles,
    articlesRoom,
    article,
    articleRoom,
    articleProgress,
    articleMultipost,
    tags,
    tag,
    categories,
    category,
    videos,
    video,
    layout,
    authors,
    author,
    settings,
    apostroph,
    media,
    infounittasks,
    notify,
    copyrights,
    copyright,
    sourcesReports,
    sourcesReport,
    reposts,
    repost,
    infounittask,
    paper,
    papers,
    documents,
    document,
    streams,
    stream,
    adsBlock,
    adsBlocks,
    adsHeadScript,
    adsHeadScripts,
    infiniteMix,
    infiniteMixList,
    bannedOrganization,
    bannedOrganizations,
    employees,
    employee,
    mainGrid,
    stories,
    story,
};
