import React, { useEffect } from 'react';
import { TagEdit } from '@mega/forms';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    useSiteStore,
    useTagDispatch,
    useTagStore,
} from '@mega/store';
import { useSnackbar } from 'notistack';
import { Tag } from '@mega/api';
import { FormikHelpers } from 'formik';

export const TagsEdit: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { id } = useParams();
    const { loading, store } = useTagStore();
    const { get, update } = useTagDispatch();
    const currentSite = useSiteStore();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            get({ id: parseInt(id) });
        }
    }, [id, get]);

    if (loading.get.loading || !loading.get.success) {
        return <div>loading</div>;
    }

    let editedId: number;

    if (id) {
        editedId = parseInt(id);
    } else {
        throw new Error('The editable entity has no id.');
    }

    const handleUpdate = async (
        payload: Tag,
        actions: FormikHelpers<Tag>,
    ) => {
        const formData = new FormData();
        for (const key in payload) {
            if (
                Object.prototype.hasOwnProperty.call(
                    payload,
                    key,
                )
            ) {
                if (key === 'site') {
                    formData.append(
                        key,
                        currentSite?.id || '',
                    );
                } else if (key === 'icon') {
                    if (
                        payload.icon !== null &&
                        typeof payload.icon === 'object'
                    ) {
                        formData.append(
                            'icon',
                            payload.icon,
                        );
                    }
                } else if (key === 'snippet_cover') {
                } else {
                    // @ts-ignore
                    formData.append(key, payload[key]);
                }
            }
        }

        await update({
            query: {
                id: editedId,
            },
            payload: formData,
            options: {
                notifier: {
                    enqueueSnackbar,
                },
                router: {
                    navigate,
                    location,
                },
            },
        });

        actions.setSubmitting(false);
    };

    return (
        <TagEdit
            handleUpdate={handleUpdate}
            initialValue={store}
        />
    );
};
