/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
    CSSProperties,
    FC,
    useEffect,
    useState,
} from 'react';

import { useField, useFormikContext } from 'formik';
import { FiCheck } from 'react-icons/fi';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import {
    checkboxClasses as classes,
    CheckboxClasses,
} from './Checkbox.css';
import { Icon } from '../../../wrappers';
import { Typography } from '../../../utility';
import { TypographyRecipeVariants } from '@mega/styles';

export interface CheckBoxProps {
    className?: string;
    label: string;
    value: string;
    name: string;
    checked: boolean;
    labelProps?: TypographyRecipeVariants;
    changeable?: boolean;
    onChange?: (meta: {
        name: string;
        value: string;
        isChecked: boolean;
    }) => void;
    onChangeEffect?: (meta: {
        name: string;
        value: string;
        isChecked: boolean;
    }) => void;
    style?: CSSProperties;
}

export const Checkbox: FC<
    CheckBoxProps &
        CheckboxClasses['recipe'] &
        CheckboxClasses['theme']
> = ({
    label,
    value,
    name,
    onChange,
    labelProps,
    checked = false,
    onChangeEffect,
    className = '',
    theme = 'primary',
    changeable = 'false',
    style = {},
}) => {
    const [isChecked, setChecked] = useState(checked);

    useEffect(() => {
        if (changeable) {
            setChecked(checked);
        }
    }, [checked]);

    useEffect(() => {
        onChangeEffect?.({ name, value, isChecked });
    }, [name, value, isChecked, onChangeEffect]);

    const handleChange = (currentCheck: boolean) => {
        setChecked(currentCheck);
        onChange?.({
            name,
            value,
            isChecked: currentCheck,
        });
    };

    return (
        <div
            className={`${classes.root({
                theme,
            })} ${className}`}
            style={{ ...style }}
        >
            <CheckboxPrimitive.Root
                id={`${name}-${value}`}
                className={classes.checkbox}
                name={name}
                checked={isChecked}
                onCheckedChange={(e) =>
                    typeof e === 'boolean'
                        ? handleChange(e)
                        : null
                }
            >
                <CheckboxPrimitive.Indicator>
                    <Icon
                        className={classes.indicator}
                        size="20"
                    >
                        <FiCheck />
                    </Icon>
                </CheckboxPrimitive.Indicator>
            </CheckboxPrimitive.Root>
            <label
                className={classes.recipe({
                    checked: isChecked,
                })}
                htmlFor={`${name}-${value}`}
            >
                <Typography
                    size="15"
                    weight="regular"
                    {...labelProps}
                >
                    {label}
                </Typography>
            </label>
        </div>
    );
};

export interface CheckboxFieldProps {
    name: string;
    label: string;
    value: any;
    theme?: CheckboxClasses['theme'];
}

export const CheckboxField: FC<CheckboxFieldProps> = ({
    name,
    label,
    value,
    theme,
}) => {
    const { values, setFieldValue } = useFormikContext();
    const [field, meta, setters] = useField({
        name,
        type: 'checkbox',
        value,
    });
    return (
        <Checkbox
            theme={theme?.theme}
            label={label}
            name={name}
            value={value}
            checked={field?.checked ?? false}
            onChange={(event) => {
                const thisIsPropertyIntoFormikContext =
                    typeof values === 'object' &&
                    values !== null &&
                    name in values;

                if (thisIsPropertyIntoFormikContext) {
                    const set = new Set(meta.value);
                    if (set.has(event.value)) {
                        set.delete(event.value);
                    } else {
                        set.add(event.value);
                    }
                    setters.setValue(Array.from(set));
                    setters.setTouched(true);
                } else {
                    const set = new Set(meta?.value ?? []);
                    if (set.has(event.value)) {
                        set.delete(event.value);
                    } else {
                        set.add(event.value);
                    }
                    setFieldValue(field.name, [
                        event.value,
                    ]);
                }
            }}
        />
    );
};
