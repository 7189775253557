import React, { FC, useEffect, useState } from 'react';

import { useField, useFormikContext } from 'formik';
import { FiCheck } from 'react-icons/fi';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import {
    checkboxClasses,
    CheckboxClasses,
} from '../Checkbox/Checkbox.css';
import { Typography } from '../../../utility';
import { Icon } from '../../../wrappers';

export interface CheckboxSwitchFieldProps {
    label: string;
    name: string;
}

const CheckboxSwitchField: FC<
    CheckboxSwitchFieldProps & CheckboxClasses['theme']
> = ({ name, label, theme = 'primary' }) => {
    const { values, setFieldValue } = useFormikContext();

    const [field, , setters] = useField({
        name,
    });

    const [isChecked, setChecked] = useState<boolean>(
        typeof field.value === 'boolean'
            ? field.value
            : false,
    );

    useEffect(() => {
        const value = isChecked;
        const thisIsPropertyIntoFormikContext =
            typeof values === 'object' &&
            values !== null &&
            name in values;

        if (thisIsPropertyIntoFormikContext) {
            setters.setValue(value);
            setters.setTouched(true);
        } else {
            setFieldValue(field.name, value);
            setters.setTouched(true);
        }
    }, [isChecked]);

    const handleChange = () => {
        setChecked(!isChecked);
    };

    useEffect(() => {
        setChecked(!!field.value);
    }, [field.value]);

    return (
        <div
            className={`${checkboxClasses.root({
                theme,
            })}`}
        >
            <CheckboxPrimitive.Root
                id={`${name}`}
                className={checkboxClasses.checkbox}
                name={name}
                checked={isChecked}
                onCheckedChange={handleChange}
            >
                <CheckboxPrimitive.Indicator>
                    <Icon
                        className={
                            checkboxClasses.indicator
                        }
                        size="20"
                    >
                        <FiCheck />
                    </Icon>
                </CheckboxPrimitive.Indicator>
            </CheckboxPrimitive.Root>
            <label htmlFor={`${name}`}>
                <Typography size="14">{label}</Typography>
            </label>
        </div>
    );
};

export { CheckboxSwitchField };
