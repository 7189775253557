import React from 'react';
import { Box } from '@mui/material';
import { ImgLoader } from '../ImgLoader';
import { definitions } from '@mega/api';
import { MediaLoaderWithMS } from '../MediaLoaderWithMS';

interface SlideProps {
    img?: definitions['AdminImage'];
    handleUpload: (res: definitions['AdminImage']) => void;
    deleteSlide: (id: number) => void;
    id: definitions['AdminImage']['id'];
    hasMS?: boolean;
}

export const Slide: React.FC<SlideProps> = (props) => {
    const { img, handleUpload, deleteSlide, hasMS } = props;
    return (
        <Box sx={{ pb: 2 }}>
            <MediaLoaderWithMS
                type={'image'}
                isMulti
                isHidden={!hasMS}
            >
                <ImgLoader
                    noDelete
                    handleLoad={handleUpload}
                    handleDelete={deleteSlide}
                    id={img?.id}
                    fileSrc={img?.image}
                    description={img?.description}
                    copyright={img?.copyright}
                    sourceUrl={img?.source_url}
                    sourceText={img?.source_text}
                />
            </MediaLoaderWithMS>
        </Box>
    );
};
